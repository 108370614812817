import logo from "./logo.svg";
import "./App.css";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Component1 from "./Component1";
import React from "react";

function App() {
  const [state, setState] = React.useState(0);

  function changeState() {
    setState((prevState) => prevState + 1);
  }

  const { width, height } = useWindowSize();

  return (
    <div className="App">

      <h1 className="title">{state}</h1>

      <div>
        {state === 10 ? <Confetti width={width} height={height} /> : ""}
      </div>

      <button onClick={changeState}>ADD</button>
    </div>
  );
}

export default App;
